import axios from 'axios';
import LocalStorageServices from '../Services/LocalStorage/index';
import { TOKEN } from './constants';
import config from '../config/apiConfig';



export const postRequest = async (configApi, apiPath, params, udid, token) => {
  let configURL = `${configApi}${apiPath}`;

  try {
    let authorization = '';
    if (token) {
      authorization = token;
    } else {
      const userToken = await LocalStorageServices.getItem(TOKEN);
      authorization = userToken ? userToken : '';
    }

    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authorization: `Bearer ${authorization}`,
    };

    console.log("POST", JSON.stringify(params))
    const response = await fetch(configURL, {
      method: 'POST',
      headers,
      body: JSON.stringify(params),
    });

    if (response.status >= 200 && response.status <= 404) {
      return response;
    } else {
      throw new Error(`${response.status}`);
    }
  } catch (error) {
    throw error;
  }
};

export const axiosPostRequest = async (configApi, apiPath, params, udid, token) => {
  let configURL = `${configApi}${apiPath}`;

  try {
    let authorization = '';
    if (token) {
      authorization = token;
    } else {
      const userToken = await LocalStorageServices.getItem(TOKEN);
      authorization = userToken ? userToken : '';
    }

    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authorization: `Bearer ${authorization}`,
    };

    const axiosInstance = axios.create({
      baseURL: configURL,
      headers: headers,
    });

    const response = await axiosInstance.post('', params);

    if (response.status >= 200 && response.status <= 404) {
      return response;
    } else {
      throw new Error(`${response.status}`);
    }
  } catch (error) {
    throw error;
  }
};

export const getRequest = async (configApi, apiPath, params, udid, token) => {
  let configURL = `${configApi}${apiPath}`;
  try {
    let authorization = token || '';
    if (!token) {
      const userToken = await LocalStorageServices.getItem(TOKEN); // Assuming TOKEN is defined somewhere
      authorization = userToken || '';
    }

    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authorization: `Bearer ${authorization}`,
    };

    const axiosInstance = axios.create({
      baseURL: configURL,
      headers: headers,
    });

    const response = await axiosInstance.get(configURL);
    
    if (response.status >= 200 && response.status < 300) {
      return response;
    } else if (response.status === 403 || response.status === 401) {
      window.location.href = `${config.cognitoURL}${config.redirectURI}${config.clientId}`; //Refresh Token
    } else {
      throw new Error(`Request failed with status ${response.status}`);
    }
  } catch (error) {
    const regex = /\b401\b/;
    if (regex.test(error.message)) {
      window.location.href = `${config.cognitoURL}${config.redirectURI}${config.clientId}`; //Refresh Token
    } else {
      throw error;
    }
  }
};

export const putRequest = async (
  configApi,
  apiPath,
  params,
  udid,
  token,
  origin,
) => {
  let configURL = `${configApi.protocol}${configApi.baseURL}${configApi.versionInfo
    }${apiPath}`;

  try {
    let authorization = '';
    if (token) {
      authorization = token;
    } else {
      const userToken = await LocalStorageServices.getItem(TOKEN);
      authorization = userToken ? userToken : '';
    }
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authorization: `Bearer ${authorization}`,
      Origin: origin,
    };
    const response = await fetch(configURL, {
      method: 'PUT', //`${method}`,
      headers,
      body: JSON.stringify(params),
    });
    return await response.json();
  } catch (error) {
    throw error;
  }
};

export const deleteRequest = async (
  configApi,
  apiPath,
  params,
  udid,
  token = '',
  body,
) => {
  const configURL = `${configApi}${apiPath}/${params}`;
  try {
    let authorization = '';
    if (token) {
      authorization = token;
    } else {
      const userToken = await LocalStorageServices.getItem(TOKEN);
      authorization = userToken ? userToken : '';
    }
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authorization: `Bearer ${authorization}`,
    };
    const response = await fetch(configURL, {
      method: 'DELETE',
      headers,
      // body: JSON.stringify(body),
    });
    if (response.status >= 200 && response.status < 300) {
      return response
    } else {
      throw new Error(`${response.status}`);
    }
    // return await response.json();
  } catch (error) {
    throw error;
  }
};

export const deleteWithQueryRequest = async (
  configApi,
  apiPath,
  params,
  udid,
  token = '',
) => {
  const configURL = `${configApi.protocol}${configApi.baseURL}${configApi.versionInfo
    }${apiPath}?${params}`;

  try {
    let authorization = '';
    if (token) {
      authorization = token;
    } else {
      const userToken = await LocalStorageServices.getItem(TOKEN);
      authorization = userToken ? userToken : '';
    }
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authorization: `Bearer ${authorization}`,
    };

    const response = await fetch(configURL, {
      method: 'DELETE',
      headers,
    });
    return response.json();
  } catch (error) {
    throw error;
  }
};

export const fetchBaseUrlRequest = async (
  configApi,
  apiPath,
  method,
  udid,
  token = '',
) => {
  const configURL = `${configApi.protocol}${configApi.baseURL}${configApi.versionInfo
    }${apiPath}`;

  try {
    const response = await fetch(configURL, {
      method: `${method}`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
        visitorId: udid,
      },
      // body: {params},
    });
    return response.json();
  } catch (error) {
    throw error;
  }
};

export const saveBaseUrlRequest = async (
  configApi,
  apiPath,
  method,
  udid,
  params,
  token = '',
) => {
  const configURL = `${configApi.protocol}${configApi.baseURL}${configApi.versionInfo
    }${apiPath}`;

  try {
    const response = await fetch(configURL, {
      method: `${method}`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,

        visitorId: udid,
      },
      body: JSON.stringify(params),
    });
    return response.json();
  } catch (error) {
    throw error;
  }
};

export const deleteBaseUrlRequest = async (
  configApi,
  apiPath,
  method,
  udid,
  params,
  token = '',
) => {
  const configURL = `${configApi.protocol}${configApi.baseURL}${configApi.versionInfo
    }${apiPath}/${params}`;

  try {
    const response = await fetch(configURL, {
      method: `${method}`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,

        visitorId: udid,
      },
    });
    return response.json();
  } catch (error) {
    throw error;
  }
};
