import {
  GET_STORE_LISTING,
  GET_CONCEPT_LISTING,
  GET_RETAILER_LISTING,
  PUT_STORE_DATA,
  GET_CATEGORY_LISTING,
  PUT_CATEGORY_DATA,
  GET_SCAN_MASTER,
  GET_SCAN_PRODUCT,
  POST_PRODUCT_STATUS,
  GET_QUANTITY_LISTING,
  GET_DISCOUNT_LISTING,
  PUT_FILE_DATA,
  DELETE_CATEGORY_DATA,
  GET_STORE_PRODUCTS,
  GET_PRODUCT_SEARCH,
  GET_FILTERED_PRODUCT,
  GET_SCAN_PRODUCT_EXTRACT,
  SEARCH_PRODUCT_SCAN,
  SEARCH_SCAN_MASTER,
  GET_SCAN_PRODUCT_EXTRACT_NEW,
} from './constants';
import config from '../../config/apiConfig';
import { getRequest, postRequest, deleteRequest, axiosPostRequest } from '../../config/apiManager';
import LocalStorageServices from '../LocalStorage';
import { TEMP_TOKEN, TOKEN, LANGUAGE } from '../../config/constants';

export const getStoreListing = callback => async dispatch => {
  let apiConfig = config.apiURL;
  let pageUrl = config.apiConfig.storeApi.storeListHandle;
  if (LocalStorageServices.getItem(TEMP_TOKEN)) {
    pageUrl = config.apiConfig.tempStoreApi.storeListHandle;
    apiConfig = config.tempApiUrl;
  }
  getRequest(apiConfig, pageUrl)
    .then(response => {
      if (response && response.data.length) {
        dispatch({
          type: GET_STORE_LISTING,
          data: response.data,
        });
        callback && callback(true, response.data);
      } else {
        callback && callback(false, response.data);
      }
    })
    .catch(error => {
      console.log(error);
      callback && callback(false);
    });
};

export const deleteCategory = (categoryId, callback) => async dispatch => {
  let apiConfig = config.apiURL;
  let pageUrl = config.apiConfig.storeApi.deleteCategory;
  if (LocalStorageServices.getItem(TEMP_TOKEN)) {
    pageUrl = config.apiConfig.tempStoreApi.deleteCategory;
    apiConfig = config.tempApiUrl;
  }
  deleteRequest(apiConfig, pageUrl, categoryId)
    .then(response => {
      if (response) {
        dispatch({
          type: DELETE_CATEGORY_DATA,
          data: response,
        });
        callback && callback(true, response);
      } else {
        callback && callback(false, response);
      }
    })
    .catch(error => {
      console.log(error);
      callback && callback(false);
    });
};

export const getScanMasterList = (pageSize, pageNumber, callback) => async dispatch => {
  let apiConfig = config.apiURL;
  let pageUrl = config.apiConfig.storeApi.getScanMasterData;
  if (LocalStorageServices.getItem(TEMP_TOKEN)) {
    pageUrl = config.apiConfig.tempStoreApi.getScanMasterData;
    apiConfig = config.tempApiUrl;
  }
  getRequest(apiConfig, `${pageUrl}/${pageSize}/${pageNumber}`)
    .then(response => {
      console.log("response",response);
      if (response && response.data.content && response.data.content.length) {
        dispatch({
          type: GET_SCAN_MASTER,
          data: response.data,
        });
        callback && callback(true, response.data);
      } else {
        callback && callback(false, response.data);
      }
    })
    .catch(error => {
      console.log(error);
      callback && callback(false);
    });
};

export const clearScanMasterList = (paramData) => async dispatch => {
  dispatch({
    type: GET_SCAN_MASTER,
    data: paramData,
  });
};

export const getCategoryListing = callback => async dispatch => {
  let apiConfig = config.apiURL;
  let pageUrl = config.apiConfig.storeApi.categoryListHandle;
  if (LocalStorageServices.getItem(TEMP_TOKEN)) {
    pageUrl = config.apiConfig.tempStoreApi.categoryListHandle;
    apiConfig = config.tempApiUrl;
  }
  getRequest(apiConfig, pageUrl)
    .then(response => {
      if (response && response.data.length) {
        dispatch({
          type: GET_CATEGORY_LISTING,
          data: response.data,
        });
        callback && callback(true, response.data);
      } else {
        callback && callback(false, response.data);
      }
    })
    .catch(error => {
      console.log(error);
      callback && callback(false);
    });
};

export const getScanProductList = (payload, callback) => async dispatch => {
  let apiConfig = config.apiURL;
  let pageUrl = `${config.apiConfig.storeApi.getScanMasterData}/filters`;
  if (LocalStorageServices.getItem(TEMP_TOKEN)) {
    pageUrl = `${config.apiConfig.tempStoreApi.getScanMasterData}/filters`;
    apiConfig = config.tempApiUrl;
  }
  var apiPayload = {
    storeId: payload.storeId,
    scanDate: payload.scanDate,
    status: payload.status,
    pageNumber: payload.pageNumber || 0,
    pageSize: payload.pageSize || 10,
    filterType: payload.filterType,
    source: payload.source,
  }
  postRequest(apiConfig, pageUrl, apiPayload)
    .then(res => {
      res.json().then((response) => {
        dispatch({
          type: GET_SCAN_PRODUCT,
          data: response,
        });
        callback && callback(true, response);
      })
    })
    .catch(error => {
      console.log(error);
      callback && callback(false);
    });
};

export const searchScanProductList = (payload, callback) => async dispatch => {
  let apiConfig = config.apiURL;
  let pageUrl = `${config.apiConfig.storeApi.searchScanArticlesData}`;
  if (LocalStorageServices.getItem(TEMP_TOKEN)) {
    pageUrl = `${config.apiConfig.tempStoreApi.searchScanMasterData}`;
    apiConfig = config.tempApiUrl;
  }
  postRequest(apiConfig, pageUrl, payload)
    .then(res => {
      res.json().then((response) => {
        dispatch({
          type: SEARCH_PRODUCT_SCAN,
          data: [],
        });
        if (response && response.length) {
          dispatch({
            type: SEARCH_PRODUCT_SCAN,
            data: response,
          });
          callback && callback(true, response);
        } else {
          callback && callback(false, response);
        }
      })
    })
    .catch(error => {
      console.log(error);
      callback && callback(false);
    });
};


export const searchScanMasterList = (searchParam, callback) => async dispatch => {
  let apiConfig = config.apiURL;
  let pageUrl = `${config.apiConfig.storeApi.searchScanMaster}/${searchParam}`;
  if (LocalStorageServices.getItem(TEMP_TOKEN)) {
    pageUrl = `${config.apiConfig.tempStoreApi.searchScanMasterData}`;
    apiConfig = config.tempApiUrl;
  }
  postRequest(apiConfig, pageUrl)
    .then(res => {
      res.json().then((response) => {
        dispatch({
          type: SEARCH_SCAN_MASTER,
          data: response,
        });
        callback && callback(true, response);
      })
    })
    .catch(error => {
      console.log(error);
      callback && callback(false);
    });
};

export const getScanProductListExtract = (payload, callback) => async dispatch => {
  let apiConfig = config.apiURL;
  let pageUrl = `${config.apiConfig.storeApi.getScanMasterData}/${payload.storeId}/${payload.scanDate}/${payload.source}`;
  if (LocalStorageServices.getItem(TEMP_TOKEN)) {
    pageUrl = `${config.apiConfig.tempStoreApi.getScanMasterData}/${payload.storeId}/${payload.scanDate}/${payload.source}`;
    apiConfig = config.tempApiUrl;
  }
  postRequest(apiConfig, pageUrl)
    .then(res => {
      console.log('abcds', res)
      res.json().then((response) => {
        console.log('abcds1', response);
        if (response && response.length) {
          dispatch({
            type: GET_SCAN_PRODUCT_EXTRACT,
          });
          callback && callback(true, response);
        } else {
          callback && callback(false, response);
        }
      })
    })
    .catch(error => {
      console.log(error);
      callback && callback(false);
    });
};


export const getScanProductListExtractNew = (payload, callback) => async dispatch => {
  let apiConfig = config.apiURL;
  let pageUrl = `${config.apiConfig.storeApi.getScanMaster}/extract/${payload.storeId}/${payload.scanDate}/${payload.source}`;
  if (LocalStorageServices.getItem(TEMP_TOKEN)) {
    pageUrl = `${config.apiConfig.tempStoreApi.getScanMaster}/extract/${payload.storeId}/${payload.scanDate}/${payload.source}`;
    apiConfig = config.tempApiUrl;
  }
  postRequest(apiConfig, pageUrl)
    .then(res => {
      console.log('abcdsNew', res)
      res.json().then((response) => {
        console.log('abcdsNew1', response);
        if (response && response.length) {
          dispatch({
            type: GET_SCAN_PRODUCT_EXTRACT_NEW,
          });
          callback && callback(true, response);
        } else {
          callback && callback(false, response);
        }
      })
    })
    .catch(error => {
      console.log(error);
      callback && callback(false);
    });
};



export const getConceptListing = callback => async dispatch => {
  let apiConfig = config.apiURL;
  let pageUrl = config.apiConfig.storeApi.getConceptArray;
  if (LocalStorageServices.getItem(TEMP_TOKEN)) {
    pageUrl = config.apiConfig.tempStoreApi.getConceptArray;
    apiConfig = config.tempApiUrl;
  }
  getRequest(apiConfig, pageUrl)
    .then(response => {
      if (response && response.data.length) {
        dispatch({
          type: GET_CONCEPT_LISTING,
          data: response.data,
        });
        callback && callback(true, response.data);
      } else {
        callback && callback(false, response.data);
      }
    })
    .catch(error => {
      console.log(error);
      callback && callback(false);
    });
};


export const getRetailerListing = callback => async dispatch => {
  let apiConfig = config.apiURL;
  let pageUrl = config.apiConfig.storeApi.getRetailerArray;
  if (LocalStorageServices.getItem(TEMP_TOKEN)) {
    pageUrl = config.apiConfig.tempStoreApi.getRetailerArray;
    apiConfig = config.tempApiUrl;
  }
  getRequest(apiConfig, pageUrl)
    .then(response => {
      if (response && response.data.length) {
        dispatch({
          type: GET_RETAILER_LISTING,
          data: response.data,
        });
        callback && callback(true, response.data);
      } else {
        callback && callback(false, response.data);
      }
    })
    .catch(error => {
      console.log(error);
      callback && callback(false);
    });
};

export const putStoreData = (payLoad, callback) => async dispatch => {
  let apiConfig = config.apiURL;
  let pageUrl = config.apiConfig.storeApi.putStoreData;
  if (LocalStorageServices.getItem(TEMP_TOKEN)) {
    pageUrl = config.apiConfig.tempStoreApi.putStoreData;
    apiConfig = config.tempApiUrl;
  }
  postRequest(apiConfig, pageUrl, payLoad)
    .then(response => {
      if (response && response.status === 200) {
        callback && callback(true, response);
        dispatch({
          type: PUT_STORE_DATA,
          data: response,
        });
      } else {
        response.json().then((res) => {
          callback && callback(false, res);
        })
      }
    })
    .catch(error => {
      callback && callback(false, error);
    });
};

export const putCategoryData = (payLoad, callback) => async dispatch => {
  let apiConfig = config.apiURL;
  let pageUrl = config.apiConfig.storeApi.putCategoryData;
  if (LocalStorageServices.getItem(TEMP_TOKEN)) {
    pageUrl = config.apiConfig.tempStoreApi.putCategoryData;
    apiConfig = config.tempApiUrl;
  }
  postRequest(apiConfig, pageUrl, payLoad)
    .then(response => {
      if (response && response.status === 200) {
        callback && callback(true, response);
        dispatch({
          type: PUT_CATEGORY_DATA,
          data: response,
        });
      } else {
        callback && callback(false, response);
      }
    })
    .catch(error => {
      callback && callback(false);
    });
};

export const postProductsApprovalStatus = (payLoad, callback) => async dispatch => {
  let apiConfig = config.apiURL;
  let pageUrl = config.apiConfig.storeApi.putProductDetails;
  if (LocalStorageServices.getItem(TEMP_TOKEN)) {
    pageUrl = config.apiConfig.tempStoreApi.putProductDetails;
    apiConfig = config.tempApiUrl;
  }
  postRequest(apiConfig, pageUrl, payLoad)
    .then(response => {
      if (response && response.status === 200) {
        callback && callback(true, response);
        dispatch({
          type: POST_PRODUCT_STATUS,
          data: response,
        });
      } else {
        callback && callback(false, response);
      }
    })
    .catch(error => {
      callback && callback(false);
    });
};

export const getQuantityUnitData = callback => async dispatch => {
  let apiConfig = config.apiURL;
  let pageUrl = config.apiConfig.commonApi.fetchQuantity;
  if (LocalStorageServices.getItem(TEMP_TOKEN)) {
    pageUrl = config.apiConfig.tempCommonApi.fetchQuantity;
    apiConfig = config.tempApiUrl;
  }
  try {
    getRequest(apiConfig, pageUrl)
      .then(response => {
        if (response && response.data.length) {
          dispatch({
            type: GET_QUANTITY_LISTING,
            data: response.data,
          });
          dispatch(getDiscountListing());
        } else {
          callback && callback(false, response.data);
        }
      })
      .catch(error => {
        callback && callback(false);
      });
  } catch (error) {
    callback && callback(false);
  }
};

export const getDiscountListing = callback => async dispatch => {
  let apiConfig = config.apiURL;
  let selectedLanguage = LocalStorageServices.getItem(LANGUAGE);
  let pageUrl = `${config.apiConfig.commonApi.fetchDiscountDetails}/${selectedLanguage}`;
  if (LocalStorageServices.getItem(TEMP_TOKEN)) {
    pageUrl = `${config.apiConfig.tempCommonApi.fetchDiscountDetails}/${selectedLanguage}`;
    apiConfig = config.tempApiUrl;
  }
  try {
    getRequest(apiConfig, pageUrl)
      .then(response => {
        if (response && response.data.length) {
          dispatch({
            type: GET_DISCOUNT_LISTING,
            data: response.data,
          });
          callback && callback(true, response.data);
        } else {
          callback && callback(false, response.data);
        }
      })
      .catch(error => {
        callback && callback(false);
      });
  } catch (error) {
    callback && callback(false);
  }
};


const uploadFileBridge = async body => {
  let apiConfig = config.apiURL;
  let pageUrl = config.apiConfig.storeApi.uploadProductMaster;
  if (await LocalStorageServices.getItem(TEMP_TOKEN)) {
    pageUrl = config.apiConfig.tempStoreApi.uploadProductMaster;
    apiConfig = config.tempApiUrl;
  }
  try {
    const userToken = await LocalStorageServices.getItem(TOKEN);
    let authorization = userToken ? userToken : '';
    const url = `${apiConfig}${pageUrl}`;
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        // 'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${authorization}`,
      },
      body,
    });
    return await response;
  } catch (error) {
    throw error;
  }
};

export const uploadFile = (payLoad, callback) => async dispatch => {
  uploadFileBridge(payLoad)
    .then(response => {
      if (response && response.status === 200) {
        response.json().then((res) => {
          callback && callback(true, res);
          dispatch({
            type: PUT_FILE_DATA,
            data: res,
          });
        })
      } else {
        callback && callback(false, response);
      }
    })
    .catch(error => {
      console.log(error);
      callback && callback(false, error);
    });
};

export const getProductMasterListing = callback => async dispatch => {
  let apiConfig = config.apiURL;
  let pageUrl = config.apiConfig.storeApi.fetchProductMaster;
  if (await LocalStorageServices.getItem(TEMP_TOKEN)) {
    pageUrl = config.apiConfig.tempStoreApi.fetchProductMaster;
    apiConfig = config.tempApiUrl;
  }
  getRequest(apiConfig, pageUrl)
    .then(response => {
      if (response && response.data.length) {
        dispatch({
          type: GET_STORE_PRODUCTS,
          data: response.data,
        });
        callback && callback(true);
      } else {
        callback && callback(false, response.data);
      }
    })
    .catch(error => {
      callback && callback(false);
    });
};

export const searchProductMasterData = (
  searchParameter,
  callback,
) => async dispatch => {
  let apiConfig = config.apiURL;
  let pageUrl = `${config.apiConfig.storeApi.searchProductMaster}/${searchParameter}`;
  if (await LocalStorageServices.getItem(TEMP_TOKEN)) {
    pageUrl = `${config.apiConfig.tempStoreApi.searchProductMaster
      }/${searchParameter}`;
    apiConfig = config.tempApiUrl;
  }
  getRequest(apiConfig, pageUrl)
    .then(response => {
      if (response.data) {
        callback && callback(true, response.data);
        dispatch({
          type: GET_PRODUCT_SEARCH,
          data: response.data,
        });
      } else {
        callback && callback(false, response.data);
        dispatch({
          type: GET_PRODUCT_SEARCH,
          data: '',
        });
      }
    })
    .catch(error => {
      dispatch({
        type: GET_PRODUCT_SEARCH,
        data: '',
      });
      callback && callback(false);
    });
};

export const filterScannedProduct = (
  payload,
  callback,
) => async dispatch => {
  let apiConfig = config.apiURL;
  let pageUrl = `${config.apiConfig.storeApi.filterScannedArticles}/${payload.storeId}/${payload.date}`;
  if (await LocalStorageServices.getItem(TEMP_TOKEN)) {
    pageUrl = `${config.apiConfig.tempStoreApi.filterScannedArticles
      }/${payload.storeId}/${payload.date}`;
    apiConfig = config.tempApiUrl;
  }
  getRequest(apiConfig, pageUrl)
    .then(response => {
      if (response.data) {
        callback && callback(true, response.data);
        dispatch({
          type: GET_FILTERED_PRODUCT,
          data: response.data,
        });
      } else {
        callback && callback(false, response.data);
        dispatch({
          type: GET_FILTERED_PRODUCT,
          data: '',
        });
      }
    })
    .catch(error => {
      dispatch({
        type: GET_FILTERED_PRODUCT,
        data: '',
      });
      callback && callback(false);
    });
};



export const downloadExcel = (fileName, selection, callback) => async dispatch => {
  let apiConfig = config.apiURL;
  var type = '';
  switch (selection) {
    case '1': type = 'allProducts'
      break;
    case '2': type = 'searchableProducts'
      break;
    case '3': type = 'nonSearchableProducts'
      break;
    default: type = 'allProducts'
  }
  let pageUrl = config.apiConfig.storeApi.downloadProductMaster;
  let selectedLanguage = LocalStorageServices.getItem(LANGUAGE);
  const userToken = await LocalStorageServices.getItem(TOKEN);
  let authorization = userToken ? userToken : '';
  const url = `${apiConfig}${pageUrl}/${selectedLanguage}/${type}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${authorization}`,
    },
  });
  const res = await response;
  res.blob().then((test) => {
    const url = window.URL.createObjectURL(test);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName + '.xlsx');
    document.body.appendChild(link);
    link.click();
    callback && callback(true)
  })
    .catch(error => {
      console.log(error);
      callback && callback(false)
    });
};

export const getScanMasterFilterList = (payload, callback) => async dispatch => {

  let apiConfig = config.apiURL;
  let pageUrl = `${config.apiConfig.storeApi.getScanMasterFilterList}/${payload.pageNumber}/${payload.pageSize}`;
  if (LocalStorageServices.getItem(TEMP_TOKEN)) {
    pageUrl = `${config.apiConfig.tempStoreApi.getScanMasterFilterList}/${payload.pageNumber}/${payload.pageSize}`;
    apiConfig = config.tempApiUrl;
  }

  // let sourceFilter = payload?.selectedSourceFilter;
  // let dateFilter = payload?.scanDateFilterValue;
  // let nameFilter = payload?.nameFilterValue;
  // let conceptFilter = payload?.conceptFilterValue;
  var apiPayload = {
    scanDate: payload?.scanDateFilterValue,
    source: payload?.selectedSourceFilter,
    name: payload?.nameFilterValue,
    concept: payload?.conceptFilterValue,
  }
  console.log("ashdajshdkadhkjahkj==========>", apiPayload);

  // const Paylload = JSON.stringify(apiPayload);
  // console.log("ashdajshdkadhkjahkj==========>", Paylload);

  // if (sourceFilter?.length > 0 && dateFilter?.length > 0 && nameFilter?.length > 0 && conceptFilter?.length > 0) {
  //     pageUrl = `${pageUrl}?scanDate=${dateFilter}&source=${sourceFilter}&name=${nameFilter}&concept=${conceptFilter}`;
  // }
  // else {
  //     if (sourceFilter?.length > 0 && dateFilter?.length > 0 && nameFilter?.length > 0) {
  //         pageUrl = `${pageUrl}?scanDate=${dateFilter}&source=${sourceFilter}&name=${nameFilter}`;
  //     }
  //     else if (sourceFilter?.length > 0 && dateFilter?.length > 0 && conceptFilter?.length > 0) {
  //         pageUrl = `${pageUrl}?scanDate=${dateFilter}&source=${sourceFilter}&concept=${conceptFilter}`;
  //     }
  //     else if (sourceFilter?.length > 0 && nameFilter?.length > 0 && conceptFilter?.length > 0) {
  //         pageUrl = `${pageUrl}?source=${sourceFilter}&name=${nameFilter}&concept=${conceptFilter}`;
  //     }
  //     else if (dateFilter?.length > 0 && nameFilter?.length > 0 && conceptFilter?.length > 0) {
  //         pageUrl = `${pageUrl}?scanDate=${dateFilter}&name=${nameFilter}&concept=${conceptFilter}`;
  //     }
  //     else {
  //         if (sourceFilter?.length > 0 && dateFilter?.length > 0) {
  //             pageUrl = `${pageUrl}?scanDate=${dateFilter}&source=${sourceFilter}`;
  //         }
  //         else if (sourceFilter?.length > 0 && nameFilter?.length > 0) {
  //             pageUrl = `${pageUrl}?source=${sourceFilter}&name=${nameFilter}`;
  //         }
  //         else if (sourceFilter?.length > 0 && conceptFilter?.length > 0) {
  //             pageUrl = `${pageUrl}?source=${sourceFilter}&concept=${conceptFilter}`;
  //         }
  //         else if (dateFilter?.length > 0 && nameFilter?.length > 0) {
  //             pageUrl = `${pageUrl}?scanDate=${dateFilter}&name=${nameFilter}`;
  //         }
  //         else if (dateFilter?.length > 0 && conceptFilter?.length > 0) {
  //             pageUrl = `${pageUrl}?scanDate=${dateFilter}&concept=${conceptFilter}`;
  //         }
  //         else if (nameFilter?.length > 0 && conceptFilter?.length > 0) {
  //             pageUrl = `${pageUrl}?name=${nameFilter}&concept=${conceptFilter}`;
  //         }
  //         else {
  //             if (sourceFilter?.length > 0) {
  //                 pageUrl = `${pageUrl}?source=${sourceFilter}`;
  //             }
  //             else if (dateFilter?.length > 0) {
  //                 pageUrl = `${pageUrl}?scanDate=${dateFilter}`;
  //             }
  //             else if (nameFilter?.length > 0) {
  //                 pageUrl = `${pageUrl}?name=${nameFilter}`;
  //             }
  //             else if (conceptFilter?.length > 0) {
  //                 pageUrl = `${pageUrl}?concept=${conceptFilter}`;
  //             }
  //         }
  //     }
  // }

  axiosPostRequest(apiConfig, pageUrl, apiPayload)
    .then(response => {
      console.log('response=======>', response.data.content);
      if (response && response.data.content && response.data.content.length) {
        dispatch({
          type: GET_SCAN_MASTER,
          data: response.data,
        });
        callback && callback(true, response.data);
      } else {
        callback && callback(false, response.data);
      }
    })
    .catch(error => {
      console.log(error);
      callback && callback(false);
    });
};